import React from "react"
import HorseDetail from "./horse"
import useHorseImgQuery from "../../hooks/use-horse-img-query"

const HorseList = ({ horses }) => {
  const nodes = useHorseImgQuery()
  const horseList = horses.map(({ node: { childMarkdownRemark } }, i) => {
    const {
      node: {
        childImageSharp: { gatsbyImageData },
      },
    } = nodes.find(
      ({ node }) => node.name === childMarkdownRemark.frontmatter.img
    )
    return (
      <HorseDetail
        key={`horse-${i}`}
        horse={childMarkdownRemark}
        img={gatsbyImageData}
      />
    )
  })
  return (
    <div className="my-3 md:my-5 md:my-10 w-full md:w-2/3">{horseList}</div>
  )
}

export default HorseList
