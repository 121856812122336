import React from "react"
import { graphql } from "gatsby"

import BaseLayout from "../components/layout/base-layout"
import HorseList from "../components/horse-list/horse-list"
import { ContentWrapper, Hero } from "@wonder-horse/shared"

const WonderHorses = ({ data }) => {
  const {
    allFile: { edges },
    file: {
      childImageSharp: { gatsbyImageData },
    },
  } = data

  return (
    <BaseLayout title="The Wonder Horses">
      <Hero img={gatsbyImageData} />
      <ContentWrapper>
        <h1 className="mb-8 md:mb-16">The Wonder Horses</h1>
        <HorseList horses={edges} />
      </ContentWrapper>
    </BaseLayout>
  )
}

export const query = graphql`
  query HorsesQuery {
    allFile(
      filter: { relativeDirectory: { eq: "horse_profiles" } }
      sort: { fields: name, order: ASC }
    ) {
      totalCount
      edges {
        node {
          name
          childMarkdownRemark {
            html
            frontmatter {
              name
              vitals
              img
            }
          }
        }
      }
    }
    file(relativePath: { eq: "horses_hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default WonderHorses
