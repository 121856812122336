import React from "react"
import HorseImg from "./horse-img"

const HorseDetail = ({ horse, img }) => {
  const {
    frontmatter: { name, vitals },
    html,
  } = horse

  return (
    <div className="mb-20 md:mb-16 last:mb-0 flex flex-col md:flex-row">
      <HorseImg name={name} img={img} />
      <div className="mt-4 md:mt-6 md:ml-10">
        <h2 className="mb-2">{name}</h2>
        <p className="italic mb-3">{vitals}</p>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  )
}

export default HorseDetail
